































import { CompanyContact } from '@/api/interfaces/companyContact'
import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { Company } from '@/api/interfaces/company'
import { professionGestionnaire } from '@/config-constantes'

export default defineComponent({
  name: 'SelectSearchCompanyContacts',
  components: {
    SelectSearch: () => import('../m-select-search.vue'),
    CreateEditCompanyContact: () => import('../dialog/createEditCompanyContact.vue'),
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null,
    },
    company: {
      type: Object as () => Company,
      required: false,
      default: () => null,
    },
    branch: {
      type: Object as () => Company,
      required: false,
      default: () => null,
    },
  },
  watch: {
    async company () {
      if (this.company) {
        await this.load(this.company)
      }
      if (this.value) {
        if (!this.findCompanyContactInList(this.value['@id'])) {
          this.$emit('input', null)
        }
      }
    },
    // load gestionnaires de branche + remove them if clear branch value
    async branch (newValue) {
      if (this.company) {
        if (newValue) {
          await this.load([this.company, this.branch])
        } else if (!newValue) {
          await this.load(this.company)
        }
      }
    },
  },
  data () {
    return {
      dialogGestionnaire: false,
      isSubmiting: false,
      companyContactEdit: {},
    }
  },
  async created () {
    if (this.company) {
      if (this.branch) {
        await this.load([this.company, this.branch])
      } else {
        await this.load(this.company)
      }
    }
  },
  computed: {
    ...mapState('companyContactList', {
      companyContactList: 'list',
    }),
    companyName (): string {
      return this.company ? (this.company.name as string) : ''
    },
    managers (): Array<CompanyContact> {
      return this.companyContactList.filter((item: CompanyContact) => item.profession?.label === professionGestionnaire)
    },
  },
  methods: {
    ...mapActions('companyContactList', {
      load: 'load',
      post: 'post',
    }),
    onInput (uri: string) {
      const companyContact = this.findCompanyContactInList(uri)
      this.$emit('input', companyContact)
    },
    findCompanyContactInList (uri: string) {
      return (this.companyContactList as Array<CompanyContact>).find(x => x['@id'] === uri)
    },
    getValidationObserver () {
      return this.$refs.observer as InstanceType<typeof ValidationObserver>
    },
    toggleModal () {
      this.dialogGestionnaire = !this.dialogGestionnaire
    },
    editCompanyContact () {
      this.toggleModal()
      this.companyContactEdit = { ...this.value }
    },
    onClose () {
      this.dialogGestionnaire = false
      this.companyContactEdit = {}
    },
    onCompanyContactCreated (companyContact: CompanyContact) {
      this.$emit('input', companyContact)
    },
    async updateCompanyContact (companyContact: CompanyContact) {
      this.$emit('input', companyContact)
      if (this.branch) {
        await this.load([this.company, this.branch])
      } else {
        await this.load(this.company)
      }
    },
    itemText (item: CompanyContact): string {
      return item.lastName + ' ' + (item.firstName || '')
    },
  },
})
